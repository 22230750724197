import React from "react"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Layout1 = ({ location, center, right }) => {
  return (
    <Row>
      <Col className="col-auto body1 mb-2">{center}</Col>
      <Col className="col-auto body2">{right}</Col>
    </Row>
  )
}

export default Layout1
